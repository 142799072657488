import { Button, Modal, Select, Tooltip } from "antd";
import React, { useState } from "react";

import { DownloadOutlined } from "@ant-design/icons";
import { isAPIError } from "../indexTypes";
import { getTaxonomyExport } from "../reportApi";
import { MetadataAnalysisOptions } from "../types/dashboardTypes";

interface ExportTaxonomyModalProps {
  metadataAnalysisOptions: MetadataAnalysisOptions;
  taxonomyId: string;
  taxonomyNodeId: string;
  viewId: string;
  startDate: string;
  endDate: string;
}
export const ExportTaxonomyModal: React.FC<ExportTaxonomyModalProps> = ({
  taxonomyId,
  taxonomyNodeId,
  viewId,
  metadataAnalysisOptions,
  startDate,
  endDate,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const options = Object.keys(metadataAnalysisOptions).map((field: string) => ({
    label: field,
    value: field,
  }));
  return (
    <div>
      <Tooltip title="Download example conversations from this issue category" placement="left">
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          disabled={downloadIsLoading}
          loading={downloadIsLoading}
          onClick={e => {
            e.stopPropagation();
            setIsModalOpen(true);
          }}
        />
      </Tooltip>
      <Modal
        title="Export Examples"
        open={isModalOpen}
        onOk={async e => {
          e.stopPropagation();
          setDownloadIsLoading(true);
          try {
            const response = await getTaxonomyExport(
              taxonomyId,
              taxonomyNodeId,
              viewId,
              startDate,
              endDate,
              selectedFields
            );
            if (response.body) {
              const outputFilename = `spiral_examples_${startDate}_to_${endDate}.csv`;
              // I know this looks gross, but apparently this is the typical way to
              // download a file returned from an API call
              const blob = await response.blob();
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", outputFilename);
              document.body.appendChild(link);
              link.click();
              link.remove();
            }
          } catch (e) {
            if (isAPIError(e)) {
              console.error(e);
            }
          } finally {
            setDownloadIsLoading(false);
            setIsModalOpen(false);
          }
        }}
        onCancel={e => {
          e.stopPropagation();
          setIsModalOpen(false);
        }}
        confirmLoading={downloadIsLoading}
        mask={false}
        wrapProps={{
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
          },
        }}
      >
        <p>Select metadata fields to include in your export:</p>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="None"
          onChange={(item: string[]) => setSelectedFields(item)}
          options={options}
        />
      </Modal>
    </div>
  );
};
