/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Affix, Button, Checkbox, Col, Input, Radio, Row, Select, Space, Spin, Switch } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { debounce, isEqual } from "lodash";
import moment, { Moment } from "moment";
import React, { memo, useCallback, useEffect, useReducer, useState } from "react";
import { CustomerUIModel, useCustomer } from "../hooks";
import {
  Category,
  CategoryList,
  DisplayDateFormat,
  Filter,
  Report,
  REPORT_TITLE_TEMPLATE,
  Sentiment,
  SortByOptions,
  TrendColors,
} from "../indexTypes";
import { API, APIParams } from "../reportApi";
import { displayName, isDefined, isSidebarReportItem, truncate } from "../utils";

import { ChartType, ClusterList, ratingNames, ReportOverviewCard } from "./ReportsComponents";

import "./ReportsViewer.less";

import { SearchOutlined } from "@ant-design/icons";
import { MetadataField } from "../reports";
import ErrorBoundary from "./ErrorBoundary";

const marginStyle = { margin: "0 24px 24px" };
const { Option } = Select;

const DaySelector = memo(
  ({
    day,
    selectDay,
    dayOfWeek,
  }: {
    day: number;
    dayOfWeek: number;
    selectDay: (e: RadioChangeEvent) => void;
  }) => (
    <Row key="daybuttons" style={marginStyle}>
      <Col>
        <Radio.Group value={day} onChange={selectDay}>
          <Radio.Button disabled={dayOfWeek < 1} value={0}>
            Monday
          </Radio.Button>
          <Radio.Button disabled={dayOfWeek < 2} value={1}>
            Tuesday
          </Radio.Button>
          <Radio.Button disabled={dayOfWeek < 3} value={2}>
            Wednesday
          </Radio.Button>
          <Radio.Button disabled={dayOfWeek < 4} value={3}>
            Thursday
          </Radio.Button>
          <Radio.Button disabled={dayOfWeek < 5} value={4}>
            Friday
          </Radio.Button>
          <Radio.Button disabled={dayOfWeek < 6} value={5}>
            Saturday
          </Radio.Button>
          <Radio.Button disabled={dayOfWeek < 7} value={6}>
            Sunday
          </Radio.Button>
        </Radio.Group>
      </Col>
    </Row>
  )
);
DaySelector.displayName = "DaySelector";

const now = moment();

const dateFormat = "YYYY-MM-DD";

const formatDateDisplay = (
  startDate: Moment,
  endDate: Moment,
  displayFormat: DisplayDateFormat
) => {
  let displayString = displayFormat.display;
  if (displayFormat.startDateFormat) {
    displayString = displayString.replace(
      "START_DATE",
      startDate.format(displayFormat.startDateFormat)
    );
  }
  if (displayFormat.endDateFormat) {
    displayString = displayString.replace("END_DATE", endDate.format(displayFormat.endDateFormat));
  }
  return displayString;
};

// ReportsViewer contains everything in a report beside the sidebar and filters.
// eslint-disable-next-line react/display-name
const ReportsViewer = memo(
  ({
    apiParams,
    report,
    customer,
    admin = false,
    analyzeBy,
    setAnalyzeBy,
    setAnalyzeByOptions,
    showDisabled,
    setShowDisabled,
    useSuperclusters,
    sortBy,
    sentimentFilter,
    categoryFilter,
    trendColors,
    searchInput,
  }: {
    apiParams: APIParams;
    report: Report;
    customer: CustomerUIModel;
    admin?: boolean;
    analyzeBy?: string;
    setAnalyzeBy: (v: string) => void;
    setAnalyzeByOptions: (v: string[]) => void;
    showDisabled: boolean;
    setShowDisabled: (v: boolean) => void;
    useSuperclusters: boolean;
    sortBy: SortByOptions;
    sentimentFilter?: Sentiment;
    categoryFilter?: Category;
    trendColors?: TrendColors;
    searchInput?: string;
  }) => {
    const reportIndex = report.reportIndexJson;
    const [chartType, setChartType] = useState<ChartType>("pie");
    const [normalized, setNormalized] = useState(false);
    const [stat, setStat] = useState<number>(0);
    const [dayCounts, setDayCounts] = useState<MetadataField>();
    const [apiLoading, setApiLoading] = useState(false);

    const setChartNormalized = useCallback((v: boolean) => {
      setNormalized(v);
      if (v) {
        setChartType("bar");
      }
    }, []);

    const setChartTypeFn = useCallback((t: ChartType) => setChartType(t), []);

    useEffect(() => {
      const options = reportIndex.analyzeByOptions;
      if (options) {
        setAnalyzeByOptions(options);
        if (!analyzeBy || !options.includes(analyzeBy))
          setAnalyzeBy(reportIndex.defaultAnalyzeBy ?? options[0]);
      }
    });

    useEffect(() => {
      setShowDisabled(admin);
    }, [admin, setShowDisabled]);

    // Rebuild the whole report: ReportOverview, Cluster list, etc...
    useEffect(() => {
      const promises = [];
      setApiLoading(true);
      const controller = new AbortController();
      if (!reportIndex.analyzeByOptions) {
        const optionsCall = API.analyzeByOptions(controller.signal, apiParams);
        promises.push(optionsCall);
        optionsCall.then(options => {
          setAnalyzeByOptions(options);
          if (!analyzeBy || !options.includes(analyzeBy))
            setAnalyzeBy(reportIndex.defaultAnalyzeBy ?? options.sort()[0]);
        });
      }

      const counts = API.dayCounts(controller.signal, apiParams);
      promises.push(counts);
      counts.then(setDayCounts).catch(error => {
        if (error.name !== "AbortError") {
          console.error(error.message);
        }
      });

      counts
        .then(counts => setStat(counts.data.map(d => d.count).reduce((a, b) => a + b, 0)))
        .catch(error => {
          if (error.name !== "AbortError") {
            console.error(error.message);
          }
        });

      setChartType(ratingNames.includes(analyzeBy ?? "") ? "bar" : "pie");

      Promise.allSettled(promises).then(() => {
        if (!controller.signal.aborted) setApiLoading(false);
      });

      return () => controller.abort();
    }, [analyzeBy, apiParams]);

    // Get the display name for the report and replace any instance of the template string in the report's legend text
    // with it. If we can't get the report's display name (though we should always be able to), default to just the
    // legend text unmodified.
    const reportDisplayName = customer.index.reportSetHierarchy
      .filter(isSidebarReportItem)
      .find(s => s.reportSet == report.urlHash)?.displayName;
    const legendText = reportDisplayName
      ? reportIndex.overviewLegendText.replaceAll(REPORT_TITLE_TEMPLATE, reportDisplayName)
      : reportIndex.overviewLegendText;
    const loading = apiLoading;

    return (
      <>
        <ErrorBoundary>
          <ReportOverviewCard
            loading={loading}
            dayCounts={dayCounts}
            reportIndex={reportIndex}
            legendText={legendText}
            stat={stat}
            apiParams={apiParams}
            analyzeBy={analyzeBy}
            chartType={chartType}
            setChartTypeFn={setChartTypeFn}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <ClusterList
            apiParams={apiParams}
            sentimentFilter={sentimentFilter}
            categoryFilter={categoryFilter}
            report={report}
            customer={customer}
            useSuperclusters={useSuperclusters}
            showDisabled={showDisabled}
            sortBy={sortBy}
            admin={admin}
            stat={stat}
            chartType={chartType}
            setChartType={setChartTypeFn}
            normalized={normalized}
            setNormalized={setChartNormalized}
            analyzeBy={analyzeBy}
            trendColors={trendColors}
            searchInput={searchInput}
          />
        </ErrorBoundary>
      </>
    );
  }
);

const ReportSetViewer = memo(
  ({ report, admin, customer }: { report: Report; admin: boolean; customer: CustomerUIModel }) => {
    const reportIndex = report.reportIndexJson;
    const [baseDay, setBaseDay] = useState<Moment>();

    const [filters, setFilters] = useState<{ [field: string]: string[] | undefined }>({});
    const [filterField, setFilterField] = useState<string>();
    const [filterValue, setFilterValue] = useState<string[]>();
    const [discreteFilters, setDiscreteFilters] = useState<{
      [field: string]: string[] | undefined;
    }>({});
    const [sentimentFilter, setSentimentFilter] = useState<Sentiment | undefined>(
      reportIndex.sentimentToShowByDefault
    );
    const [categoryFilter, setCategoryFilter] = useState<Category | undefined>();

    const [endDate, setEndDate] = useState<string>();

    const initialParamsState: APIParams = {
      customer: customer.id,
      window: {
        start: moment(reportIndex.endDate)
          .subtract(reportIndex.periodLength, reportIndex.period)
          .toISOString(),
        end: moment(moment(reportIndex.endDate) ?? now)
          .subtract(1, "day")
          .format(dateFormat),
        interval: reportIndex.period,
        periods: reportIndex.periodLength,
      },
      latestLength: reportIndex.latestLength,
      datasets: reportIndex.datasets ?? [],
      filters: [],
    };

    // Using APIParams in a normal useState is prone to triggering too many updates because it creates a new Javascript
    // object on each update which may be functionally equivalent but don't have strict equality. Instead this custom
    // reducer will return the same JS object if they are equivalent, suppressing unnecessary requests.
    function reducer(state: APIParams, newState: APIParams) {
      if (isEqual(state, newState)) {
        return state;
      }
      return { ...newState };
    }
    const [apiParams, setApiParams] = useReducer(reducer, initialParamsState);

    const [sortBy, setSortBy] = useState<SortByOptions>("latest");
    const [analyzeBy, setAnalyzeBy] = useState<string>();
    const [analyzeByOptions, setAnalyzeByOptions] = useState<string[]>([]);

    const [showDisabled, setShowDisabled] = useState(false);
    const [useSuperclusters, setUseSuperclusters] = useState(
      reportIndex.superclustersOnByDefault ?? true
    );

    const [searchInput, setSearchInput] = useState<string>();

    const getDefaultParams = () => {
      return initialParamsState;
    };
    const debouncedSetApiParams = useCallback(debounce(setApiParams, 300), []);

    // For when the customer or report is changed, this is only meant to recalculate endDate
    useEffect(() => {
      const controller = new AbortController();

      const boundsAPIStuff = async () => {
        if (!reportIndex.endDate) {
          const bounds = await API.bounds(controller.signal, getDefaultParams());
          let eDate = reportIndex.useLatestDate ? bounds.filterless_max : bounds.max;
          if (!eDate) {
            eDate = moment(now).subtract(1, "day").format(dateFormat);
          }
          setEndDate(eDate);
          setBaseDay(moment(eDate));
        } else {
          setEndDate(reportIndex.endDate);
          setBaseDay(moment(reportIndex.endDate));
        }
      };

      boundsAPIStuff();
      return () => controller.abort();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // only should trigger when changing a report
    }, [report]);

    // Called when any incremental update needs to happen, eg: date forward/back buttons or filters
    useEffect(() => {
      const rebuildApiParams = () => {
        if (!endDate) {
          return;
        }
        const startDate = moment(endDate)
          .subtract(reportIndex.periodLength, reportIndex.period)
          .toISOString();
        const base: Filter[] = [];
        debouncedSetApiParams({
          customer: customer.id,
          window: {
            start: startDate,
            end: endDate,
            interval: reportIndex.period,
            periods: reportIndex.periodLength,
          },
          latestLength: reportIndex.latestLength,
          datasets: reportIndex.datasets ?? [],
          filters: base.concat(
            Object.entries(filters)
              .filter(([, v]) => isDefined(v))
              .filter(([, v]) => v!.length > 0)
              .map(([k, v]) => ({ field: k, values: v! }))
          ),
        });
      };

      rebuildApiParams();
    }, [endDate, baseDay, JSON.stringify(filters)]);

    const adjustDate = (direction: "forward" | "backward") => {
      const newDate = moment(apiParams.window.end).add(
        (direction === "backward" ? -1 : 1) * (reportIndex.datePicker?.amount ?? 1),
        reportIndex.datePicker?.unit
      );
      const dpConfig = reportIndex.datePicker;
      if (dpConfig) {
        const snapConfig = dpConfig.snapTo;
        if (snapConfig) {
          if (snapConfig.direction === "start") {
            newDate.startOf(snapConfig.unit);
          } else {
            newDate.endOf(snapConfig.unit);
          }
        }
      }
      return newDate.format(dateFormat);
    };

    useEffect(() => {
      setDiscreteFilters(
        Object.fromEntries(
          Object.entries(reportIndex.filters ?? []).map(([fId, f]) => [
            fId,
            f.required ? [Object.keys(f.values)[0]] : undefined,
          ])
        )
      );
      setSortBy(filterValue ? "overall" : reportIndex.defaultPercent ?? "latest");
      if (
        !isDefined(reportIndex.filters) ||
        (filterField && !(filterField in reportIndex.filters)) ||
        (filterField &&
          filterValue &&
          !filterValue.every(v => v in reportIndex.filters![filterField!].values))
      ) {
        setFilterField(undefined);
        setFilterValue(undefined);
      }
    }, [reportIndex, filterField, filterValue]);

    useEffect(() => {
      if (filterField && filterValue) {
        setFilters({ ...discreteFilters, ...{ [filterField]: filterValue } });
      } else {
        setFilters({ ...discreteFilters });
      }
    }, [discreteFilters, filterField, filterValue]);

    return (
      <>
        <Affix className="topcontrols">
          <div>
            {(reportIndex.showSearch ?? true) && (
              <Row style={{ margin: "0 24px 0px", padding: "12px 0 0 0" }}>
                <Col span={24}>
                  <Input
                    placeholder="Search Categories and Sub-Categories"
                    size="large"
                    allowClear
                    value={searchInput}
                    onChange={({ target: { value } }) => setSearchInput(value)}
                    prefix={<SearchOutlined />}
                  />
                </Col>
              </Row>
            )}
            {endDate && baseDay && (reportIndex.showDateButtons ?? true) && (
              <Row
                key="Buttons"
                style={{ margin: "0 24px 0px", padding: "12px 0 0 0" }}
                justify="space-between"
              >
                <Col span={24}>
                  <Space size="middle">
                    <Button onClick={() => setEndDate(adjustDate("backward"))}>&lt;</Button>
                    <span style={{ fontSize: "1.5em", color: "rgba(0,0,0,0.85)", fontWeight: 500 }}>
                      {reportIndex.displayDateFormat &&
                        formatDateDisplay(
                          moment(apiParams.window.start),
                          moment(apiParams.window.end),
                          reportIndex.displayDateFormat
                        )}
                    </span>
                    <Button
                      disabled={moment(endDate).isSameOrAfter(baseDay, "day")}
                      onClick={() =>
                        setEndDate(
                          moment.min(moment(adjustDate("forward")), baseDay).format(dateFormat)
                        )
                      }
                    >
                      &gt;
                    </Button>
                    {moment(apiParams.window.end).isBefore(baseDay, "day") && (
                      <Button
                        onClick={() => {
                          setEndDate(baseDay.format(dateFormat));
                        }}
                      >
                        Latest
                      </Button>
                    )}
                  </Space>
                </Col>
              </Row>
            )}
            <Row style={{ margin: "0 24px 12px" }} className="topcontrolsrow">
              <Col span={14}>
                {reportIndex && (
                  <Space size="small">
                    {reportIndex.showSuperclustersToggle && (
                      <>
                        <label htmlFor="groupby">Group Issues:</label>
                        <Checkbox
                          id="groupby"
                          checked={useSuperclusters}
                          onChange={e => setUseSuperclusters(e.target.checked)}
                        />
                      </>
                    )}
                    {reportIndex.showSentimentFilter && (
                      <>
                        <span>Sentiment:</span>
                        <Select
                          value={sentimentFilter}
                          style={{ width: 85 }}
                          allowClear
                          placeholder="All"
                          onChange={setSentimentFilter}
                          dropdownMatchSelectWidth={false}
                        >
                          {Object.entries({
                            positive: "Positive",
                            neutral: "Neutral",
                            negative: "Negative",
                          }).map(([valueId, value]) => (
                            <Select.Option key={valueId} value={valueId}>
                              {value}
                            </Select.Option>
                          ))}
                        </Select>
                      </>
                    )}
                    <>
                      <span>Category:</span>
                      <Select
                        value={categoryFilter}
                        style={{ width: 100 }}
                        allowClear
                        placeholder="All"
                        onChange={setCategoryFilter}
                        options={CategoryList.map(category => {
                          return { label: displayName(category), value: category };
                        })}
                      ></Select>
                    </>
                    {reportIndex.filters &&
                      Object.entries(reportIndex.filters).filter(
                        ([, field]) => field.discrete === false
                      ).length > 0 && (
                        <Space>
                          <span>Filter by:</span>
                          <Select
                            getPopupContainer={(triggerNode: HTMLElement) =>
                              triggerNode.parentNode as HTMLElement
                            }
                            showSearch
                            value={filterField}
                            style={{ width: 140 }}
                            allowClear
                            filterOption={(input, option) =>
                              (option!.children as unknown as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={value => {
                              setFilterField(value === "" ? undefined : value);
                              setFilterValue(undefined);
                            }}
                            dropdownMatchSelectWidth={false}
                            placeholder="Choose Filter"
                          >
                            {filterField && (
                              <Select.Option key="blank" value="">
                                {" "}
                              </Select.Option>
                            )}
                            {Object.entries(reportIndex.filters)
                              .filter(([, field]) => field.discrete === false)
                              .map(([fieldId, field]) => (
                                <Select.Option key={fieldId} value={fieldId}>
                                  {field.displayName ??
                                    displayName(fieldId, reportIndex.displayNames)}
                                </Select.Option>
                              ))}
                          </Select>
                          {filterField && (
                            <Select
                              getPopupContainer={(triggerNode: HTMLElement) =>
                                triggerNode.parentNode as HTMLElement
                              }
                              showSearch
                              value={filterValue}
                              style={{ width: 140, paddingRight: 10, zIndex: 9999 }}
                              allowClear={false}
                              filterOption={(input, option) =>
                                isDefined(option) &&
                                option.key.toLowerCase().includes(input.toLowerCase())
                              }
                              onChange={value => {
                                setFilterValue(value);
                              }}
                              dropdownMatchSelectWidth={false}
                              mode="multiple"
                              placeholder="Choose Value"
                            >
                              {filterField &&
                                filterField in reportIndex.filters &&
                                Object.entries(reportIndex.filters[filterField].values).map(
                                  ([valueId, value]) => (
                                    <Select.Option key={valueId} value={valueId}>
                                      {truncate(
                                        value.displayName ??
                                          displayName(valueId, reportIndex.displayNames),
                                        60
                                      )}
                                    </Select.Option>
                                  )
                                )}
                            </Select>
                          )}
                        </Space>
                      )}
                    {reportIndex.filters &&
                      Object.entries(reportIndex.filters)
                        .filter(([, field]) => field.discrete !== false)
                        .map(([fieldId, field]) => (
                          <Space key={fieldId} size="middle">
                            <span>{displayName(fieldId, reportIndex.displayNames)}:</span>
                            <Select
                              value={discreteFilters[fieldId]}
                              style={{ width: 240 }}
                              allowClear={!field.required}
                              onChange={value => {
                                setDiscreteFilters({ ...discreteFilters, ...{ [fieldId]: value } });
                              }}
                              dropdownMatchSelectWidth={false}
                              mode="multiple"
                            >
                              {Object.entries(field.values).map(([valueId, value]) => (
                                <Select.Option key={valueId} value={valueId}>
                                  {value.displayName ??
                                    displayName(valueId, reportIndex.displayNames)}
                                </Select.Option>
                              ))}
                            </Select>
                          </Space>
                        ))}
                  </Space>
                )}
              </Col>
              <Col span={10} style={{ textAlign: "right" }}>
                <Space size="large">
                  <Space size="middle">
                    <span id="overviewby">Analyze by:</span>
                    <Select
                      getPopupContainer={(triggerNode: HTMLElement) =>
                        triggerNode.parentNode as HTMLElement
                      }
                      showSearch
                      value={analyzeBy}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={val => setAnalyzeBy(val)}
                      style={{ minWidth: "235px", textAlign: "left" }}
                    >
                      {analyzeByOptions.map(v => (
                        <Option key={v} value={v}>
                          {displayName(v, reportIndex.displayNames)}
                        </Option>
                      ))}
                    </Select>
                  </Space>
                  <Space size="middle">
                    <span id="overviewby">Sort by:</span>
                    <Select
                      defaultValue={reportIndex.defaultPercent ?? "latest"}
                      value={sortBy}
                      onChange={val => setSortBy(val)}
                      style={{ minWidth: "120px", textAlign: "left" }}
                    >
                      <Option value="latest">Latest</Option>
                      <Option value="overall">Overall</Option>
                      <Option value="trend">Change</Option>
                      <Option value="increasing">Change (Increasing)</Option>
                      <Option value="decreasing">Change (Decreasing)</Option>
                    </Select>
                  </Space>
                </Space>
              </Col>
            </Row>
            {admin && (
              <Row style={{ margin: "0 24px 0px", padding: "0 0 12px" }}>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Space size="large">
                    <div style={{ color: "red" }}>ADMIN MODE</div>
                    <Space size="middle">
                      <span id="overviewby">Show Disabled:</span>
                      <Switch checked={showDisabled} onChange={setShowDisabled} />
                    </Space>
                  </Space>
                </Col>
              </Row>
            )}
          </div>
        </Affix>

        {endDate && reportIndex ? (
          <ReportsViewer
            customer={customer}
            apiParams={apiParams}
            admin={admin}
            report={report}
            sortBy={sortBy}
            analyzeBy={analyzeBy}
            setAnalyzeBy={setAnalyzeBy}
            setAnalyzeByOptions={setAnalyzeByOptions}
            showDisabled={showDisabled}
            setShowDisabled={setShowDisabled}
            useSuperclusters={useSuperclusters}
            sentimentFilter={sentimentFilter}
            categoryFilter={categoryFilter}
            trendColors={customer.index.trendColors}
            searchInput={searchInput}
          />
        ) : (
          <Spin />
        )}
      </>
    );
  }
);

export const ReportsViewerWrapper = ({ report, admin }: { report: Report; admin: boolean }) => {
  const { customer } = useCustomer();

  const [adminMode, setAdminMode] = useState(false);

  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (!admin) return;
      if (e.key == "A" && e.shiftKey) {
        setAdminMode(!adminMode);
      }
    };
    window.addEventListener("keyup", keyHandler);
    return () => {
      window.removeEventListener("keyup", keyHandler);
    };
  }, [admin, adminMode]);

  return (
    <div id="reportsviewer">
      <Row key="spacer" style={{ margin: "0 24px 12px" }}>
        <Col />
      </Row>
      {<ReportSetViewer admin={adminMode} customer={customer} report={report} />}
    </div>
  );
};
