import { CaretDownOutlined, CaretUpOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip as AntTooltip, Button, Col, Row, Space } from "antd";
import React from "react";

import { useDashboardContext } from "../hooks";
import { TimeseriesMetadata } from "../indexTypes";
import { MetadataAnalysisOptions } from "../types/dashboardTypes";
import { formatPercent } from "../utils";
import { ExportTaxonomyModal } from "./ExportTaxonomyModal";
import { TimeseriesChart } from "./TimeseriesChart";

const formatPeriod = (prd: string) => {
  switch (prd) {
    // We don't need to display the full period in hours
    case "hour":
    case "day":
      return "d";
    case "month":
      return "mo";
    case "week":
      return "w";
    case "year":
      return "yr";
  }
};

function getPeriodLengthInGranularity(periodInMilliseconds: number, granularity: string) {
  switch (granularity) {
    // We don't need to display the full period in hours
    case "hour":
    case "day":
      return periodInMilliseconds / 1000 / 60 / 60 / 24;
    case "week":
      return periodInMilliseconds / 1000 / 60 / 60 / 24 / 7;
    case "month":
      return periodInMilliseconds / 1000 / 60 / 60 / 24 / 30;
    case "year":
      return periodInMilliseconds / 1000 / 60 / 60 / 24 / 365;
    default:
      return 0;
  }
}

interface TaxonomyNodeHeaderProps {
  taxonomyNodeId: string;
  name: string;
  data: TimeseriesMetadata;
  total: number;
  mean: number;
  granularity: string;
  startDate: string;
  endDate: string;
  periodTrend: number;
  scale: number;
  percent: number;
  parentId: string | undefined;
  nodeChildren: string[];
  nodeGrandchildrenCount: number;
  metadataAnalysisOptions: MetadataAnalysisOptions;
}

export const TaxonomyNodeHeader: React.FC<TaxonomyNodeHeaderProps> = ({
  taxonomyNodeId,
  name,
  data,
  total,
  mean,
  percent,
  granularity,
  startDate,
  endDate,
  periodTrend,
  scale,
  parentId = undefined,
  nodeChildren = [],
  nodeGrandchildrenCount = 0,
  metadataAnalysisOptions,
}) => {
  const dateWindowPeriodInMillis = new Date(endDate).getTime() - new Date(startDate).getTime();
  const dateWindowByGranularity = getPeriodLengthInGranularity(
    dateWindowPeriodInMillis,
    granularity
  );
  const defaultLatestPeriodInMillis = 14 * 24 * 60 * 60 * 1000; // TODO: remove hardcoded default latest period
  const defaultLatestPeriodByGranularity = getPeriodLengthInGranularity(
    defaultLatestPeriodInMillis,
    granularity
  );
  const latestLength = Math.min(dateWindowByGranularity, defaultLatestPeriodByGranularity);
  const { state } = useDashboardContext();

  const renderChildCountTag = (count: number, typeString: string, className: string) => {
    return (
      <Button
        key={`${count} ${typeString}s`}
        className={className}
        type={className === "solid-btn" ? "primary" : "default"}
      >
        {count} {typeString}
        {count > 1 ? "s" : ""}
      </Button>
    );
  };

  const renderChildrenCounts = () => {
    if (nodeChildren.length > 0 && nodeGrandchildrenCount == 0) {
      return renderChildCountTag(nodeChildren.length, "Issue", "solid-btn");
    }

    if (nodeChildren.length > 0 && nodeGrandchildrenCount > 0) {
      return (
        <Space size="middle">
          {renderChildCountTag(nodeChildren.length, "Topic", "solid-btn")}
          {renderChildCountTag(nodeGrandchildrenCount, "Issue", "outline-btn")}
        </Space>
      );
    }
  };

  return (
    <Row>
      <div className="highlight" />
      <Col span={nodeChildren.length > 0 ? 18 : 21}>
        <Space size={25} key={taxonomyNodeId}>
          <span>{name}</span>
          <div className="dot" />
          <div className="field">
            <AntTooltip title={() => <span>Percent of total contacts over the whole period</span>}>
              <div className="field-desc">
                Percent <InfoCircleOutlined />
              </div>
              {percent && formatPercent(percent)}
            </AntTooltip>
          </div>
          <div className="dot" />
          <div className="field">
            <AntTooltip
              title={() => (
                <span>Total number of contacts in this group over the whole period</span>
              )}
            >
              <div className="field-desc">
                Volume <InfoCircleOutlined />
              </div>
              <span>{total && total.toLocaleString()}</span>
            </AntTooltip>
          </div>
          <div className="dot" />
          <div className="field">
            <AntTooltip
              title={() => (
                <span>
                  Average number of contacts in this group per {granularity} over the whole period
                </span>
              )}
            >
              <div className="field-desc">
                Avg Rate <InfoCircleOutlined />
              </div>
              <span>
                {mean && Math.round(mean).toLocaleString()}
                <small>/{granularity}</small>
              </span>
            </AntTooltip>
          </div>
          <div className="dot" />
          <Space size={"middle"}>
            <div className="field">
              <AntTooltip
                title={() => (
                  <span>
                    Change in Volume over {latestLength} {granularity}
                    {latestLength > 1 ? "s" : ""}{" "}
                  </span>
                )}
              >
                <div className="field-desc">
                  {latestLength}
                  {formatPeriod(granularity)} Change <InfoCircleOutlined />
                </div>
                <span>
                  {`${periodTrend > 0 ? "+" : ""} ${periodTrend.toFixed(2)}%`}
                  {periodTrend > 0 ? (
                    <CaretUpOutlined style={{ padding: "0 7px" }} />
                  ) : (
                    <CaretDownOutlined style={{ padding: "0 7px" }} />
                  )}
                </span>
              </AntTooltip>
            </div>
            <TimeseriesChart
              metadata={data}
              graphheight={55}
              graphwidth={132}
              range={parentId && scale ? [0, scale] : undefined}
              showEmpty={false}
            />
          </Space>
        </Space>
      </Col>
      <Col span={nodeChildren.length > 0 ? 6 : 3}>
        <Space className="header-tags" size="middle">
          <Col span={12}>{renderChildrenCounts()}</Col>
          <Col span={12}>
            {/* Only show the evidence export button if we're viewing a L2 or L3 issue OR
                if issues are ungrouped
             */}
            {(parentId || !state.issuesGrouped) && (
              <ExportTaxonomyModal
                taxonomyId={state.taxonomy?.id || ""}
                taxonomyNodeId={taxonomyNodeId}
                viewId={state.view?.id || ""}
                startDate={startDate}
                endDate={endDate}
                metadataAnalysisOptions={metadataAnalysisOptions}
              />
            )}
          </Col>
        </Space>
      </Col>
    </Row>
  );
};
