import React, { useState } from "react";

import { Cascader, Space, Tooltip } from "antd";
import type { DefaultOptionType } from "antd/es/cascader";

import { remove as _remove, escapeRegExp, isNumber, isString, isUndefined, truncate } from "lodash";
import { useDashboardContext } from "../hooks";
import { Sentiment } from "../indexTypes";
import { FilterOptions, Settings } from "../types/dashboardTypes";
import {
  BooleanExpression,
  ComparableExpression,
  EqOp,
  MatchesOp,
  MetadataNumericValueField,
  MetadataValueField,
  ParsedExpression,
  StringExpression,
} from "../types/expressionsDslTypes";

/**
 * Merge filter keys that are mapped to the same display name so we can show them as a single filter
 */
const mergeFilterSettingsWithSameDisplayName = (viewFilters: Settings["filters"]) => {
  const uniqueDisplayNames = new Set(
    Object.keys(viewFilters).map((filterKey: string) => viewFilters[filterKey].display_name)
  );

  const mergedOptions = Array.from(uniqueDisplayNames).map(displayName => {
    const keys = Object.keys(viewFilters).filter(
      filterKey => viewFilters[filterKey].display_name === displayName
    );
    return { keys, displayName };
  });

  return mergedOptions;
};

const buildMetadataFilterExpression = (
  opId: MatchesOp["op_id"] | EqOp<StringExpression | ComparableExpression>["op_id"],
  lhsFieldId: MetadataNumericValueField["field_id"] | MetadataValueField["field_id"],
  lhsMetadataKey: MetadataNumericValueField["metadata_key"] | MetadataValueField["metadata_key"],
  rhsValue: string | number
): BooleanExpression => {
  return {
    expr: {
      op_id: opId,
      lhs: { expr: { field_id: lhsFieldId, metadata_key: lhsMetadataKey } },
      rhs: { expr: rhsValue },
    },
  } as BooleanExpression;
};

const buildMetadataFilterExpressions = (
  keys: string[],
  values: (string | number)[]
): BooleanExpression => {
  const exprAry: BooleanExpression[] = keys.map(key => {
    if (isNumber(values[0])) {
      const exprs = values.map(val =>
        buildMetadataFilterExpression("EQ", "metadata_numeric_value", key, val)
      );
      const metadataExpressions =
        exprs.length === 1 ? exprs[0] : { expr: { op_id: "ANY", expressions: exprs } };
      return metadataExpressions as BooleanExpression;
    } else {
      // Construct a string that is python-parseable regex
      let regex = values
        .map(val => {
          if (!isString(val)) {
            throw new Error("Invalid filter value");
          }
          return escapeRegExp(val);
        })
        .join("|");
      // Make the regex case-insensitive
      regex = `(?i)${regex}`;

      return buildMetadataFilterExpression("MATCHES", "metadata_value", key, regex);
    }
  });

  if (exprAry.length === 0) {
    throw new Error("Invalid filter value");
  }
  const expr = exprAry.length === 1 ? exprAry[0] : { expr: { op_id: "ANY", expressions: exprAry } };
  return expr as BooleanExpression;
};

export const MultiFilter: React.FC<{
  viewFilters: Settings["filters"];
  filterOptions: FilterOptions | undefined;
  filterOptionsLoading: boolean;
}> = ({ viewFilters, filterOptions, filterOptionsLoading }) => {
  const { dispatch } = useDashboardContext();
  const [error, setError] = useState<string | undefined>(undefined);
  // TODO: Remove this when we implement BE support for sentiment filter
  const SHOW_SENTIMENT_FILTER = false;

  interface Option {
    key: string;
    value: string | number; // Must be string or number; Cascader does not support other types
    label: string | number | React.JSX.Element;
    children?: Option[];
    parent?: string;
    parentDisplayName?: string;
  }

  type SelectedOptions = (string | number)[][];

  const SENTIMENT_KEY = "sentiment";
  const sentimentChildren = ["positive", "negative", "neutral"].map(entry => ({
    key: entry,
    value: entry,
    label: entry,
    parent: SENTIMENT_KEY,
    parentDisplayName: "Sentiment",
  }));
  const sentimentOption = {
    key: SENTIMENT_KEY,
    value: SENTIMENT_KEY,
    label: "Issue Sentiment",
    children: sentimentChildren,
  };

  // When filter settings point to the same display name, merge them into a single filter
  const mergedFilterSettings = mergeFilterSettingsWithSameDisplayName(viewFilters);

  const options: Option[] = mergedFilterSettings
    .map((filterConfig: { keys: string[]; displayName: string }) => {
      // Merge options when filters share a display name
      const mergedOptions: (string | number)[] = filterConfig.keys
        .map(fieldName =>
          filterOptions && fieldName in filterOptions ? filterOptions[fieldName] : []
        )
        .flat()
        .sort();

      // Filter out case insensitive duplicates and undefined values
      const uniqueOptions: Set<string | number> = new Set();
      const uniqueMergedOptions = mergedOptions.filter(opt => {
        if (isString(opt) && !uniqueOptions.has(opt.toLowerCase())) {
          uniqueOptions.add(opt.toLowerCase());
          return true;
        }
        if (!isString(opt) && !isUndefined(opt) && !uniqueOptions.has(opt)) {
          uniqueOptions.add(opt);
          return true;
        }
        return false;
      });

      if (uniqueMergedOptions.length === 0) {
        return undefined;
      }

      // JSONify the keys since they're arrays; cascader does not support array keys or values.
      const fieldKey = JSON.stringify(filterConfig.keys);

      return {
        key: fieldKey,
        value: fieldKey,
        label: filterConfig.displayName,
        children: uniqueMergedOptions.map((childOption: string | number) => ({
          key: childOption.toString(),
          value: childOption,
          label:
            isString(childOption) && childOption.length > 30 ? (
              <Tooltip title={childOption}>{truncate(childOption, { length: 30 })}</Tooltip>
            ) : (
              childOption
            ),
          parent: fieldKey,
          parentDisplayName: filterConfig.displayName,
        })),
      };
    })
    .filter(option => option !== undefined) as Option[];

  if (SHOW_SENTIMENT_FILTER) {
    // TODO: Remove this when we implement BE support for sentiment filter
    options.push(sentimentOption);
  }

  const filtersToExpression = (selectedOptions: SelectedOptions): ParsedExpression | undefined => {
    try {
      if (selectedOptions.length === 0) {
        return;
      }

      // Group the selected filters by their keys and build expressions for each group
      const uniqueJsonMetadataKeys = Array.from(
        new Set(selectedOptions.map(option => option[0] as string))
      );
      const expressions = uniqueJsonMetadataKeys.map(metadataKeysStr => {
        const values = selectedOptions
          .filter(option => option[0] === metadataKeysStr)
          .map(option => option[1]);
        const keys = JSON.parse(metadataKeysStr) as string[];
        return buildMetadataFilterExpressions(keys, values);
      });

      // Wrap the expressions in an ALL operator if there are multiple
      const topLevelExpression: BooleanExpression =
        expressions.length === 1 ? expressions[0] : { expr: { op_id: "ALL", expressions } };

      const filterExpression: ParsedExpression = {
        version: 1,
        parsed: topLevelExpression,
      };
      return filterExpression;
    } catch (e) {
      console.error(e);
      setError("Filters could not be applied");
    }
  };

  const onChange = (selectedOptions: SelectedOptions) => {
    setError(undefined);
    // Remove the sentiment filter from the selected options so we can handle it separately
    const selectedSentiment = _remove(selectedOptions, value => value[0] === SENTIMENT_KEY);

    const MAX_FILTERS = 10;
    if (selectedOptions.length > MAX_FILTERS) {
      setError(`Please select ${MAX_FILTERS} or fewer filters`);
      return;
    }
    const formattedFilters = filtersToExpression(selectedOptions);
    dispatch({ type: "SET_FILTERS", payload: formattedFilters });
    if (selectedSentiment.length > 0) {
      dispatch({
        type: "SET_TAXONOMY_NODE_FILTERS",
        payload: { sentiment: selectedSentiment[0][1] as Sentiment },
      });
    }
  };

  const renderSelectedFilters = (
    labels: string[],
    selectedOptions: DefaultOptionType[] | undefined
  ) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      return;
    }

    const option = selectedOptions[selectedOptions.length - 1];
    const label = labels[labels.length - 1];
    if (selectedOptions.length > 1 && option.parent) {
      return (
        <span key={option.value}>
          {option.parentDisplayName}: {label}
        </span>
      );
    } else {
      return <span key={option.value}>{option.label}: All</span>;
    }
  };

  return (
    <Space>
      <Cascader
        dropdownClassName="dashboard-multi-filter-cascader"
        style={{ minWidth: 300, width: "100%" }}
        placeholder="Apply Filter(s)"
        options={options}
        onChange={onChange}
        displayRender={renderSelectedFilters}
        multiple
        status={error ? "error" : undefined}
        maxTagCount={7} // Selected options are truncated in the UI after maxTagCount items
        loading={filterOptionsLoading}
      />
      {error && <span style={{ color: "red" }}>{error}</span>}
    </Space>
  );
};
