import { Auth } from "aws-amplify";
import moment from "moment";
import { CustomerUIModel } from "./hooks";
import {
  APIError,
  AuthIntegration,
  AuthIntegrationTypes,
  Category,
  Customer,
  CustomerIndex,
  DatasetFilterCounts,
  Filter,
  GetOrderedTaxonomyNodesResponse,
  GetTaxonomyNodeExampleConversationsRequest,
  GetTaxonomyNodeExampleConversationsResponse,
  Period,
  Report,
  ReportDatasetInclude,
  ReportIndex,
  ReportIndexAudit,
  ReportWindow,
  Sentiment,
  TaxonomyNodeFilters,
  User,
} from "./indexTypes";
import {
  Example,
  MetadataField,
  ReportingDataResp,
  SortableClusterSummary,
  Titles,
} from "./reports";
import { MetadataCounts } from "./types/dashboardTypes";
import { ParsedExpression } from "./types/expressionsDslTypes";
import { endpoint, fetchWithReject, headers } from "./utils";

export const apiV2Endpoint = process.env.REACT_APP_API_V2_ENDPOINT;

const bearerToken = () =>
  Auth.currentSession().then(session => `Bearer ${session.getIdToken().getJwtToken()}`);

export const customerIndexes = async (signal?: AbortSignal): Promise<CustomerUIModel[]> =>
  fetchWithReject(`${endpoint}/customer_indexes`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: await bearerToken(),
    },
    mode: "cors",
    signal,
  }).then(r => r.json());

export interface APIParams {
  customer: string;
  filters: Filter[];
  window: ReportWindow;
  latestLength: number;
  datasets: ReportDatasetInclude[];
}

interface DBReportAPI {
  metadata: (
    signal: AbortSignal,
    params: APIParams,
    field: string,
    limit: number
  ) => Promise<MetadataField>;
  clusterMetadata: (
    signal: AbortSignal,
    params: APIParams,
    field: string,
    clusters: string[],
    limit: number
  ) => Promise<MetadataField>;
  clusters: (
    signal: AbortSignal,
    params: APIParams,
    reportIndexId: string,
    showDisabled: boolean,
    sentimentFilter: Sentiment | "all",
    categoryFilter: Category | "",
    useMegaclusters?: boolean
  ) => Promise<{ clusters: SortableClusterSummary[]; superclusters: SortableClusterSummary[] }>;
  evidences: (
    signal: AbortSignal,
    params: APIParams,
    cluster: string,
    bypassCache?: boolean
  ) => Promise<Example[]>;
  timeStart: (signal: AbortSignal, params: APIParams) => Promise<string>;
  analyzeByOptions: (signal: AbortSignal, params: APIParams) => Promise<string[]>;
  dayCounts: (signal: AbortSignal, params: APIParams) => Promise<MetadataField>;
  bounds: (
    signal: AbortSignal,
    params: APIParams
  ) => Promise<{ min: string; max: string; filterless_min: string; filterless_max: string }>;
}

class APIImpl implements DBReportAPI {
  private api = endpoint + "/overview";

  buildParams = (func: string, params: APIParams, extra?: { [key: string]: string | string[] }) =>
    new URLSearchParams({
      ...{
        func,
        customer: params.customer,
        date: params.window.end,
        freq: params.window.interval,
        periods: params.window.periods.toString(),
        datasets: JSON.stringify(params.datasets),
        filters: JSON.stringify(params.filters),
      },
      ...extra,
    }).toString();

  get = async (
    signal: AbortSignal,
    func: string,
    params: APIParams,
    extra?: { [key: string]: string | string[] },
    bypassCache = false
  ) =>
    fetchWithReject(`${this.api}?${this.buildParams(func, params, extra)}`, {
      method: "GET",
      headers: {
        Authorization: await bearerToken(),
      },
      cache: bypassCache ? "reload" : "default",
      mode: "cors",
      signal,
    }).then(r => r.json());

  timeStart = (_signal: AbortSignal, params: APIParams) =>
    new Promise<string>(resolve =>
      resolve(
        moment(params.window.end)
          .subtract(1, params.window.interval as Period)
          .toISOString()
      )
    );

  analyzeByOptions = () => new Promise<string[]>(resolve => resolve([]));

  dayCounts = async (signal: AbortSignal, params: APIParams) =>
    this.get(signal, "dayCounts", params) as Promise<MetadataField>;

  metadata = async (signal: AbortSignal, params: APIParams, field: string, limit: number) =>
    this.get(signal, "metadata", params, { field, analyzeByLimit: limit.toString() });

  clusterMetadata = async (
    signal: AbortSignal,
    params: APIParams,
    field: string,
    clusterIds: string[],
    limit: number
  ) =>
    this.get(signal, "metadata", params, {
      field,
      cluster: clusterIds,
      analyzeByLimit: limit.toString(),
    });

  clusters = async (
    signal: AbortSignal,
    params: APIParams,
    reportIndexId: string,
    showDisabled: boolean,
    sentimentFilter: Sentiment | "all",
    categoryFilter: Category | "",
    useMegaclusters = false
  ) =>
    this.get(signal, "clusters", params, {
      reportIndexId,
      sentimentFilter,
      categoryFilter,
      showDisabled: showDisabled.toString(),
      useMegaclusters: useMegaclusters.toString(),
    });

  evidences = async (
    signal: AbortSignal,
    params: APIParams,
    cluster: string,
    bypassCache = false
  ) => this.get(signal, "evidences", params, { cluster }, bypassCache);

  bounds = async (signal: AbortSignal, params: APIParams) => this.get(signal, "bounds", params);
}

export const API: DBReportAPI = new APIImpl();

export const updateCustomerIndex = async (
  id: string,
  index: CustomerIndex
): Promise<CustomerIndex | APIError> => {
  const session = await Auth.currentSession();
  return fetchWithReject(`${endpoint}/customer_index/${id}`, {
    method: "POST",
    body: JSON.stringify(index),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
    },
    mode: "cors",
  }).then(r => r.json());
};

export const getReportIndex = async (signal: AbortSignal, id: string) =>
  fetchWithReject(`${endpoint}/report_index/${id}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
    signal,
  }).then(r => r.json() as unknown as ReportIndex);

export const updateReportIndex = async (id: string, index: ReportIndex) =>
  fetchWithReject(`${endpoint}/report_index/${id}`, {
    method: "POST",
    body: JSON.stringify(index),
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const renameReport = async (
  id: string,
  newName: string
): Promise<{ auditLog: ReportIndexAudit[] } | APIError> =>
  fetchWithReject(`${endpoint}/report_index/${id}/rename/${newName}`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const setReportTitles = async (
  reportId: string,
  titlesId: string
): Promise<{ auditLog: ReportIndexAudit[] } | APIError> =>
  fetchWithReject(`${endpoint}/report_index/${reportId}/set_titles/${titlesId}`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getReportIndexAudit = async (
  signal: AbortSignal,
  id: string
): Promise<{ auditLog: ReportIndexAudit[] } | APIError> =>
  fetchWithReject(`${endpoint}/report_index/${id}/audit`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
    signal,
  }).then(r => r.json());

export const getTitlesFile = async (signal: AbortSignal, customerId: string, id: string) =>
  fetchWithReject(`${endpoint}/customers/${customerId}/titles_file/${id}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
    signal,
  }).then(r => r.json() as unknown as Titles);

export const updateTitlesFile = async (customerId: string, id: string, titles: Titles) =>
  fetchWithReject(`${endpoint}/customers/${customerId}/titles_file/${id}`, {
    method: "POST",
    body: JSON.stringify(titles),
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const datasetsOverview = async () =>
  fetchWithReject(`${endpoint}/datasets/overview`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export type TimeBuckets = "month" | "year";
export const datasetsByBucket = async (
  bucket: TimeBuckets,
  filteredCustomers: string[],
  filteredSource: string[]
) =>
  fetchWithReject(
    `${endpoint}/bucketed_datasets?bucket=${bucket}&customers=${filteredCustomers}&sources=${filteredSource}`,
    {
      method: "GET",
      headers: await headers(),
      mode: "cors",
    }
  ).then(r => r.json());

export const sources = async (customers: string[]) =>
  fetchWithReject(`${endpoint}/sources?customers=${customers}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getTitlesFiles = async (customer: string) =>
  fetchWithReject(`${endpoint}/customer/${customer}/titles`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getReports = async (customer: string) =>
  fetchWithReject(`${endpoint}/customer/${customer}/reports`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const createReport = async (
  customer: string,
  reportName: string,
  report: ReportIndex
): Promise<Report | APIError> =>
  fetchWithReject(`${endpoint}/customer/${customer}/reports/${reportName}`, {
    method: "POST",
    headers: await headers(),
    body: JSON.stringify(report),
    mode: "cors",
  }).then(r => r.json());

export const deleteReport = async (
  customer_id: string,
  reportSetId: string
): Promise<Report | APIError> =>
  fetchWithReject(`${endpoint}/customer/${customer_id}/reports/${reportSetId}/delete`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const createTitles = async (
  customer: string,
  titlesName: string,
  titlesFileToCopyFrom: string | undefined
): Promise<Report | APIError> => {
  const body: { titles_name: string; copy_from?: string | undefined } = {
    titles_name: titlesName,
  };
  if (titlesFileToCopyFrom) {
    body.copy_from = titlesFileToCopyFrom;
  }
  const response = await fetchWithReject(`${endpoint}/customer/${customer}/titles`, {
    method: "POST",
    headers: await headers(),
    body: JSON.stringify(body),
    mode: "cors",
  });
  return response.json();
};

export type Cluster = {
  id: string;
  customerId: string;
  title?: string;
  defaultTitle: string;
  sentiment?: Sentiment;
  enabled?: boolean;
  createdAt: string;
};

export type ClustersResponse = {
  clusters: Cluster[];
  training_data: {
    [cluster_id: string]: {
      positive: { id: string; text: string }[];
      negative: { id: string; text: string }[];
    };
  };
  counts: {
    [cluster_id: string]: number;
  };
  total: number;
};

export const getCustomerClusters = async (customer: string): Promise<ClustersResponse> =>
  fetchWithReject(`${endpoint}/customer/${customer}/clusters`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getCustomerClustersExamples = async (
  customer: string,
  clusterId?: string,
  showHighProbability?: boolean
) =>
  fetchWithReject(
    `${endpoint}/customer/${customer}/clusters/examples/${clusterId}?direction=${
      showHighProbability ? "desc" : "asc"
    }`,
    {
      method: "GET",
      headers: await headers(),
      mode: "cors",
    }
  ).then(r => r.json());

export const getCustomerConversationQueue = async (customer: string) =>
  fetchWithReject(`${endpoint}/customer/${customer}/conversation-queue`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getUsers = async (): Promise<{ users: User[] }> =>
  fetchWithReject(`${endpoint}/users`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getUser = async (userId: string) =>
  fetchWithReject(`${endpoint}/users/${userId}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const updateUser = async (user: User) =>
  fetchWithReject(`${endpoint}/users/${user.cognitoSub}`, {
    method: "PUT",
    headers: await headers(),
    body: JSON.stringify(user),
    mode: "cors",
  }).then(r => r.json());

export const getEvidences = async (
  customer_id: string,
  search_terms: string,
  use_classified_evidences: boolean
) =>
  fetchWithReject(`${endpoint}/customer/${customer_id}/search-evidences`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({
      searchTerms: search_terms,
      useClassifiedEvidences: use_classified_evidences,
    }),
  }).then(r => r.json());

export const getEvidenceCsv = async (
  clusterId: string,
  clusterIds: Array<string> | undefined,
  apiParams: APIParams,
  reportUrlHash: string,
  metadataFields: Array<string>
) => {
  const customer = apiParams.customer;
  const fullEndpoint = `${endpoint}/customer/${customer}/cluster/${clusterId}/evidence/csv`;
  const bodyObj = {
    cluster_ids: clusterIds,
    report_url_hash: reportUrlHash,
    metadata_fields: metadataFields,
    ...apiParams,
  };
  const body = JSON.stringify(bodyObj);
  const response = await fetchWithReject(fullEndpoint, {
    method: "POST",
    headers: await headers(),
    body,
    mode: "cors",
  });

  return response;
};

export const getTaxonomyExport = async (
  taxonomyId: string,
  taxonomyNodeId: string,
  viewId: string,
  startDate: string,
  endDate: string,
  metadataFields: Array<string>,
  filters?: ParsedExpression
) => {
  const requestParams = {
    startDate,
    endDate,
    metadataFields,
    viewId,
    filters,
  };
  const body = JSON.stringify(requestParams);
  const fullEndpoint = `${apiV2Endpoint}/taxonomies/${taxonomyId}/node/${taxonomyNodeId}/export`;
  const response = await fetchWithReject(fullEndpoint, {
    method: "POST",
    headers: await headers(),
    body,
    mode: "cors",
    cache: "no-cache",
  });

  return response;
};

export const getConversationMetadataFields = async (
  clusterIds: Array<string>,
  customerId: string
) => {
  const queryParam = encodeURIComponent(JSON.stringify(clusterIds));
  const fullEndpoint = `${endpoint}/conversation/customer/${customerId}/metadata_fields?cluster_ids=${queryParam}`;
  const response = await fetchWithReject(fullEndpoint, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  });

  return response;
};

export const getClustersPage = async (customer_id: string) =>
  fetchWithReject(`${endpoint}/clusters/${customer_id}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getClassifiers = async () =>
  fetchWithReject(`${endpoint}/cluster-studio`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getClassifier = async (id: string) =>
  fetchWithReject(`${endpoint}/cluster-studio/classifier-models/${id}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getClusters = async () =>
  fetchWithReject(`${endpoint}/cluster-studio/clusters`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getCluster = async (id: string) =>
  fetchWithReject(`${endpoint}/cluster-studio/clusters/${id}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const createCluster = async (
  customer_id: string,
  training_data: { positive: string[]; negative: string[] },
  title?: string
) =>
  fetchWithReject(`${endpoint}/clusters/create`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({
      customer_id,
      training_data,
      title,
      operation: "create",
    }),
  }).then(r => r.json());

export const deleteCluster = async (cluster_id: string) =>
  fetchWithReject(`${endpoint}/clusters/${cluster_id}/delete`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const editClusterTrainingData = async (
  customer_id: string,
  cluster_id: string,
  training_data: { positive: string[]; negative: string[] }
) =>
  fetchWithReject(`${endpoint}/clusters/${cluster_id}/edit`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({
      customer_id: customer_id,
      training_data: training_data,
    }),
  }).then(r => r.json());

export const mergeClusters = async (customer_id: string, clusters: string[]) =>
  fetchWithReject(`${endpoint}/clusters/merge`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({ customer_id: customer_id, cluster_ids: clusters }),
  }).then(r => r.json());

export const refreshClusters = async (
  customer_id: string,
  dataset_ids: string[] = [],
  start_date: string,
  end_date: string
) =>
  fetchWithReject(`${endpoint}/clusters/refresh`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({ customer_id, dataset_ids, start_date, end_date }),
  }).then(r => r.json());

export const retrainClassifier = async (
  customer_id: string,
  backfill_start_date: string,
  backfill_end_date: string
) =>
  fetchWithReject(`${endpoint}/customer/${customer_id}/retrain_and_backfill`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({
      customer: customer_id,
      backfillStartDate: backfill_start_date,
      backfillEndDate: backfill_end_date,
    }),
  }).then(r => r.json());

export const getClusterProposalBatches = async (customer_id: string) =>
  fetchWithReject(`${endpoint}/clusters/proposal_batch/${customer_id}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
    cache: "no-cache",
  }).then(r => r.json());

export const getClusterProposals = async (customer_id: string) =>
  fetchWithReject(`${endpoint}/clusters/proposals/${customer_id}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
    cache: "no-cache",
  }).then(r => r.json());

export const updateClusterProposalStatus = async (
  customer_id: string,
  proposal_id: number,
  status: string
) =>
  fetchWithReject(`${endpoint}/clusters/proposals/${customer_id}/${proposal_id}/status`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({ status: status }),
  }).then(r => r.json());

export const updateClusterProposalBatchStatus = async (
  customer_id: string,
  batch_id: number,
  status: string
) =>
  fetchWithReject(`${endpoint}/clusters/proposal_batch/${customer_id}/${batch_id}/status`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({ status: status }),
  }).then(r => r.json());

export const setClusterTitle = async (id: string, title: string) =>
  fetchWithReject(`${endpoint}/clusters/${id}/title`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({ title }),
  });

export const setClusterEnabled = async (id: string, titlesFileName: string, enabled: boolean) =>
  fetchWithReject(`${endpoint}/clusters/${id}/enable`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({ titles_file_name: titlesFileName, enabled: enabled }),
  });

export const getCustomers = async (): Promise<{ customers: Customer[] }> =>
  fetchWithReject(`${endpoint}/customers`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const updateCustomerTier = async (
  customer: string,
  tier: string
): Promise<object | APIError> =>
  fetchWithReject(`${endpoint}/customer/${customer}/tier`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({ tier: tier }),
  }).then(r => r.json());

export const updateCustomerDomains = async (
  customer: string,
  domains: string[]
): Promise<Customer | APIError> =>
  fetchWithReject(`${endpoint}/customer/${customer}/domains`, {
    method: "PATCH",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({ domains }),
  }).then(r => r.json());

export const getCustomerAuthIntegration = async (
  customer: string
): Promise<AuthIntegration | APIError> =>
  fetchWithReject(`${endpoint}/customer/${customer}/auth_integration`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getAuthIntegrationByDomain = async (
  domain: string
): Promise<{ name: string } | APIError> =>
  fetchWithReject(`${endpoint}/auth_integration/domain/${domain}`, {
    method: "GET",
    headers: {
      // Endpoint to retrieve integration for an unauthenticated user, so no token is passed to backend.
      "Content-Type": "application/json",
      "Cache-Control": "public",
    },
    mode: "cors",
  }).then(r => r.json());

export const getAuthIntegrationTypes = async (): Promise<AuthIntegrationTypes | APIError> =>
  fetchWithReject(`${endpoint}/auth_integration`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const createAuthIntegration = async (
  customer: string,
  type: string,
  name: string,
  metadata: object
): Promise<AuthIntegration | APIError> =>
  fetchWithReject(`${endpoint}/auth_integration`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({
      customer_id: customer,
      identity_type: type,
      name,
      integration_metadata: metadata,
    }),
  }).then(r => r.json());

export const deleteCustomer = async (customer: string) =>
  fetchWithReject(`${endpoint}/customer/${customer}`, {
    method: "DELETE",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const removeEvidence = async (clusteredTextId: string) =>
  fetchWithReject(`${endpoint}/remove_evidence/${clusteredTextId}`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const moveEvidence = async (clusteredTextId: string, toCluster: string) => {
  return fetchWithReject(`${endpoint}/move_evidence/${clusteredTextId}?to_cluster=${toCluster}`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());
};

export const getReportingData = async (
  customer: string,
  reportId: string,
  startDate: string,
  periodInDays: number,
  filters: Filter[] = []
): Promise<ReportingDataResp> => {
  const queryParams = new URLSearchParams({
    start_date: startDate,
    period_in_days: periodInDays.toString(),
    filters: JSON.stringify(filters),
  });
  return fetchWithReject(
    `${endpoint}/customer/${customer}/reporting/${reportId}?${queryParams.toString()}`,
    {
      method: "GET",
      headers: await headers(),
      mode: "cors",
    }
  ).then(r => r.json());
};

export type Judgement = "positive" | "negative";

interface AnnotationResponse {
  annotation: {
    id?: string;
    judgement: Judgement;
  };
}

export const annotateEvidence = async (
  clusteredTextId: string,
  judgement?: Judgement
): Promise<AnnotationResponse> => {
  let url = `${endpoint}/annotate_evidence/${clusteredTextId}`;
  if (judgement) {
    url += `?judgement=${judgement}`;
  }
  return fetchWithReject(url, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());
};

export const searchSimilar = async (
  positives: string[],
  negatives: string[],
  queryFor: "positives" | "hard_positives" | "hard_negatives" = "positives",
  rules: object
): Promise<{ results: string[] }> => {
  return fetchWithReject(`${endpoint}/search`, {
    method: "POST",
    headers: await headers(),
    body: JSON.stringify({ positives, negatives, queryFor, rules }),
    mode: "cors",
  }).then(r => r.json());
};

export const retrainModels = async (customer_id: string) =>
  fetchWithReject(`${endpoint}/clusters/retrain-models`, {
    method: "POST",
    headers: await headers(),
    body: JSON.stringify({ customer_id }),

    mode: "cors",
  }).then(r => r.json());

export type TrainingData = {
  positive: string[];
  negative: string[];
};
export type ClusterTrainingData = {
  training_data: TrainingData;
};

export const setClusterRules = async (cluster_id: string, rules: object) =>
  fetchWithReject(`${endpoint}/clusters/${cluster_id}/rules`, {
    method: "POST",
    headers: await headers(),
    body: JSON.stringify({ rules }),
    mode: "cors",
  }).then(r => r.json());

export const getClusterRules = async (cluster_id: string) =>
  fetchWithReject(`${endpoint}/clusters/${cluster_id}/rules`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getClusterTrainingData = async (cluster_id: string) =>
  fetchWithReject(`${endpoint}/clusters/${cluster_id}/training_data`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const searchUntagged = async (customer_id: string, cluster_scope: string[]) =>
  fetchWithReject(`${endpoint}/search-untagged/${customer_id}`, {
    method: "POST",
    headers: await headers(),
    body: JSON.stringify({ cluster_scope }),
    mode: "cors",
  }).then(r => r.json());

export const addTrainingData = async (
  cluster_id: string,
  training_data: TrainingData
): Promise<ClusterTrainingData> =>
  fetchWithReject(`${endpoint}/clusters/${cluster_id}/training_data`, {
    method: "POST",
    headers: await headers(),
    body: JSON.stringify({ training_data }),
    mode: "cors",
  }).then(r => r.json());

export const dataExplorerData = async (customer_id: string) =>
  fetchWithReject(`${endpoint}/data-explorer/${customer_id}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const estimateClusterSize = async (customer_id: string) =>
  fetchWithReject(`${endpoint}/estimate-cluster-size/${customer_id}`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getDataset = async (datasetUuid: string) =>
  fetchWithReject(`${endpoint}/datasets/${datasetUuid}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getDatasetFilter = async (
  datasetUuid: string,
  filterKey?: string,
  filterValue?: string,
  omitFilter?: string[],
  includeFilter?: string[],
  size?: number
): Promise<APIError | DatasetFilterCounts> => {
  const params = new URLSearchParams();
  if (omitFilter) {
    omitFilter.forEach(filter => params.append("omit", filter));
  }
  if (includeFilter) {
    includeFilter.forEach(filter => params.append("include", filter));
  }
  if (size) {
    params.append("size", size.toString());
  }
  if (filterKey && filterValue) {
    params.append("filter_by", `${filterKey}:${filterValue}`);
  }

  return fetchWithReject(`${endpoint}/datasets/${datasetUuid}/filters?` + params, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());
};

export const getExample = async (type: string, field: string) =>
  fetchWithReject(`${endpoint}/admin/examples?type=${type}&field=${field}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getConversationDebug = async (conversationId: number | string) =>
  fetchWithReject(`${endpoint}/admin/conversation-debug/${conversationId}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getWeeklyReports = async (end_date: string) =>
  fetchWithReject(`${endpoint}/admin/weekly-reports/${end_date}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const syncTitles = async (customerId: string, titlesFileId: string) =>
  fetchWithReject(`${endpoint}/customers/${customerId}/titles_file/${titlesFileId}/sync`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const saveEvidenceTags = async (
  customerId: string,
  tags: { [text_id: string]: string[] }
) => {
  return fetchWithReject(`${endpoint}/customer/${customerId}/evidence-tags`, {
    method: "POST",
    headers: await headers(),
    body: JSON.stringify(tags),
    mode: "cors",
  }).then(r => r.json());
};

export const getTaxonomyOverviewMetadata = async (
  taxonomyId: string,
  viewId: string,
  startDate: string,
  endDate: string,
  granularity: string,
  filters?: ParsedExpression,
  taxonomyNodeFilters?: TaxonomyNodeFilters,
  signal?: AbortSignal
) => {
  return fetchWithReject(`${apiV2Endpoint}/taxonomies/${taxonomyId}/timeseries/get`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({
      viewId,
      startDate,
      endDate,
      granularity,
      filters,
      taxonomyNodeFilters,
    }),
    signal,
  }).then(r => r.json());
};

export const getTaxonomyNode = async (
  taxonomyId: string,
  taxonomyNodeId: string,
  viewId: string,
  context: {
    granularity: string;
    startDate: string;
    endDate: string;
    filters?: ParsedExpression;
    taxonomyNodeFilters?: TaxonomyNodeFilters;
  },
  signal?: AbortSignal
) => {
  return fetchWithReject(`${apiV2Endpoint}/taxonomies/${taxonomyId}/node/${taxonomyNodeId}/get`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({
      ...context,
      viewId,
    }),
    signal,
  }).then(r => r.json());
};

export const getOrderedTaxonomyNodes = async (
  taxonomyId: string,
  viewId: string,
  nodeIds: string[],
  context: {
    startDate: string;
    endDate: string;
    granularity: string;
    filters?: ParsedExpression;
    taxonomyNodeFilters?: TaxonomyNodeFilters;
  },
  signal?: AbortSignal
): Promise<GetOrderedTaxonomyNodesResponse> => {
  return fetchWithReject(`${apiV2Endpoint}/taxonomies/${taxonomyId}/node_order/get`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({
      viewId,
      nodeIds,
      ...context,
    }),
    signal,
  }).then(r => r.json());
};

export const getTaxonomy = async (taxonomyId: string, signal?: AbortSignal) => {
  return fetchWithReject(`${apiV2Endpoint}/taxonomies/${taxonomyId}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
    signal,
  }).then(r => r.json());
};

export const getTaxonomyNodeExampleConversations = async (
  taxonomyId: string,
  taxonomyNodeId: string,
  getTaxonomyNodeExampleConversationsRequest: GetTaxonomyNodeExampleConversationsRequest,
  signal?: AbortSignal
): Promise<GetTaxonomyNodeExampleConversationsResponse> => {
  return fetchWithReject(
    `${apiV2Endpoint}/taxonomies/${taxonomyId}/node/${taxonomyNodeId}/examples`,
    {
      method: "POST",
      headers: await headers(),
      body: JSON.stringify(getTaxonomyNodeExampleConversationsRequest),
      mode: "cors",
      signal,
    }
  ).then(r => r.json());
};

export const getView = async (viewId: string, signal?: AbortSignal) => {
  return fetchWithReject(`${apiV2Endpoint}/views/${viewId}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
    signal,
  }).then(r => r.json());
};

export const getViewFilterOptions = async (
  viewId: string,
  startDate: string,
  endDate: string,
  signal?: AbortSignal
) => {
  const params = new URLSearchParams({ start_date: startDate, end_date: endDate });
  return fetchWithReject(`${apiV2Endpoint}/views/${viewId}/filters?${params.toString()}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
    signal,
  }).then(r => r.json());
};

export const getTags = async (customerId: string) => {
  return fetchWithReject(`${apiV2Endpoint}/${customerId}/tags`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());
};

export const getTagValidationSetCases = async (customerId: string, tagId: string) => {
  return fetchWithReject(`${apiV2Endpoint}/${customerId}/tags/${tagId}/validation_set_cases`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());
};

export const getConversation = async (conversationUuid: string) => {
  return fetchWithReject(`${apiV2Endpoint}/conversations/${conversationUuid}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());
};

export const createValidationSetCase = async (
  customer_id: string,
  set_id: string,
  tag_id: string,
  case_text: string,
  expected_result: boolean
) => {
  return fetchWithReject(`${apiV2Endpoint}/${customer_id}/tags/${tag_id}/validation_set_case`, {
    method: "POST",
    headers: await headers(),
    body: JSON.stringify({ set_id, case_text, expected_result }),
    mode: "cors",
  }).then(r => r.json());
};

export const updateValidationSetCase = async (
  customer_id: string,
  set_id: string,
  tag_id: string,
  case_id: string,
  case_text: string,
  expected_result: boolean
) => {
  return fetchWithReject(
    `${apiV2Endpoint}/${customer_id}/tags/${tag_id}/validation_set_case/${case_id}`,
    {
      method: "POST",
      headers: await headers(),
      body: JSON.stringify({ set_id, case_text, expected_result }),
      mode: "cors",
    }
  ).then(r => r.json());
};

export const deleteValidationSetCase = async (
  customer_id: string,
  set_id: string,
  tag_id: string,
  case_id: string
) => {
  return fetchWithReject(
    `${apiV2Endpoint}/${customer_id}/tags/${tag_id}/validation_set_case/${case_id}`,
    {
      method: "DELETE",
      headers: await headers(),
      body: JSON.stringify({ set_id }),
      mode: "cors",
    }
  ).then(r => r.json());
};

export const gptClassifications = async (hypothesis: string, texts: string[]) => {
  return fetchWithReject(`${apiV2Endpoint}/dummy/tags/run_gpt_classifier`, {
    method: "POST",
    headers: await headers(),
    body: JSON.stringify({ hypothesis, texts }),
    mode: "cors",
  }).then(r => r.json());
};

export const updateTagHypothesis = async (
  customer_id: string,
  tag_id: string,
  hypothesis: string
) => {
  return fetchWithReject(`${apiV2Endpoint}/${customer_id}/tags/${tag_id}/hypothesis`, {
    method: "PUT",
    headers: await headers(),
    body: JSON.stringify({ hypothesis }),
    mode: "cors",
  }).then(r => r.json());
};

export const updateTagName = async (customer_id: string, tag_id: string, name: string) => {
  return fetchWithReject(`${apiV2Endpoint}/${customer_id}/tags/${tag_id}/name`, {
    method: "PUT",
    headers: await headers(),
    body: JSON.stringify({ name }),
    mode: "cors",
  }).then(r => r.json());
};

export const getMetadataCounts = async (
  customerId: string,
  viewId: string,
  startDate: string,
  endDate: string,
  metadataFieldName: string,
  taxonomyNodeId?: string,
  valueLimit?: number,
  filters?: ParsedExpression,
  signal?: AbortSignal
): Promise<MetadataCounts | APIError> => {
  return fetchWithReject(`${apiV2Endpoint}/${customerId}/views/${viewId}/metadata_counts/get`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({
      startDate,
      endDate,
      metadataFieldName,
      filters,
      taxonomyNodeId,
      valueLimit,
    }),
    signal,
  }).then(r => r.json());
};

export const getTextSearchResults = async (
  customer_id: string,
  texts = "all",
  query?: string,
  limit = 250
) => {
  const params = new URLSearchParams({
    texts: texts,
    limit: limit.toString(),
  });
  if (query) {
    params.append("search_query", query);
  }
  return fetchWithReject(`${apiV2Endpoint}/${customer_id}/text?${params.toString()}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());
};
